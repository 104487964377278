import _setPrototypeOf from "./setPrototypeOf.js";
var exports = {};
var setPrototypeOf = _setPrototypeOf;
function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;
  setPrototypeOf(subClass, superClass);
}
exports = _inheritsLoose, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;